import styles from '../Checkout.module.scss';
import { CombinedCartItemData } from '../Checkout.types';
import OrderSummaryCard from '../OrderSummaryCard/OrderSummaryCard';

/* cartItemsCard*/
export default function OrderSummaryCards({
  cartItems,
}: Readonly<{ cartItems: CombinedCartItemData[] }>) {
  return cartItems ? (
    <>
      <p className={styles.sectionHead}>Review order summary</p>
      {cartItems.map((cartItemData, idx) => (
        <OrderSummaryCard
          cartItemData={cartItemData}
          key={`${cartItemData.cartItemUuid}-${idx}`}
          isShowingAdditionalButtons={cartItemData.subtype === 'team'}
        />
      ))}
    </>
  ) : null;
}
/* */
