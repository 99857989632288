import { NotePencil, Trash } from '@phosphor-icons/react';
import { IconButton } from '@la/ds-ui-components';
import { CartItemOptionsSubprogramsDetail } from '@la/types';
import styles from './OrderSummaryCard.module.scss';

/* ProgramRegistrationsTable */
export default function ProgramRegistrationsTable({
  subprograms,
  subtype,
}: Readonly<{
  subprograms: CartItemOptionsSubprogramsDetail[];
  subtype: string;
}>) {
  switch (subtype) {
    case 'team':
      return <TeamProgramRegistrationsTable subprograms={subprograms} />;
    case 'player':
    case 'staff':
      return (
        <PlayerStaffRegistrationsTable
          subprograms={subprograms}
          subtype={subtype}
        />
      );
    default:
      return null;
  }
}
/* */

/* TeamProgramRegistrationsTable */
function TeamProgramRegistrationsTable({
  subprograms,
}: Readonly<{ subprograms: CartItemOptionsSubprogramsDetail[] }>) {
  const headers: string[] = ['Program', 'Team', 'Total price'];
  return (
    <table
      className={styles.programRegistrationsTable}
      aria-label="team program registrations"
    >
      <ProgramRegistrationsTableHeader headers={headers} />
      <tbody>
        {subprograms.map(({ subprogramName, registrations }) => (
          <>
            {registrations.map(({ basePrice, registrationId, teamName }) => (
              <tr key={registrationId}>
                <td>{subprogramName}</td>
                <td>{teamName}</td>
                <td>${basePrice}</td>
              </tr>
            ))}
          </>
        ))}
      </tbody>
    </table>
  );
}
/* */

/* PlayerStaffRegistrationsTable */
function PlayerStaffRegistrationsTable({
  subprograms,
  subtype,
}: Readonly<{
  subprograms: CartItemOptionsSubprogramsDetail[];
  subtype: string;
}>) {
  const headers = [];
  if (subtype === 'player') {
    headers.push('Player');
  } else if (subtype === 'staff') {
    headers.push('Staff', 'Role');
  }
  headers.push('Status', 'Program', 'Team', 'Total price', '', '');

  return (
    <table
      className={styles.programRegistrationsTable}
      aria-label={`${subtype} program registrations`}
    >
      <ProgramRegistrationsTableHeader headers={headers} />
      <tbody>
        {subprograms.map(({ subprogramName, registrations }) => (
          <>
            {registrations.map(
              ({ basePrice, registrationId, role, teamName, userName }) => (
                <tr key={registrationId}>
                  <td>{userName}</td>
                  {subtype === 'staff' ? <td>{role}</td> : null}
                  <td>{teamName ? 'Team' : 'Free Agent'}</td>
                  <td>{subprogramName}</td>
                  <td>{teamName}</td>
                  <td>${basePrice}</td>
                  <RegistrationActions registrationId={registrationId} />
                </tr>
              )
            )}
          </>
        ))}
      </tbody>
    </table>
  );
}
/* */

/*ProgramRegistrationsTableHeader */
function ProgramRegistrationsTableHeader({
  headers,
}: Readonly<{ headers: string[] }>) {
  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
}
/* */

/* RegistrationActions */
function RegistrationActions({
  registrationId,
}: Readonly<{
  registrationId: string;
}>) {
  const onEditButtonClick = (): void => {
    window.location.href = `/app/registration/review?registrationId=${registrationId}`;
  };

  const onDeleteButtonClick = (): void => {
    // TODO: Add functionality for deletion once endpoint is included
  };

  return (
    <>
      <td>
        <IconButton
          ariaLabel="Edit"
          icon={<NotePencil weight="bold" />}
          onClick={onEditButtonClick}
          variant="text"
        />
      </td>
      <td>
        <IconButton
          ariaLabel="Delete"
          icon={<Trash weight="bold" />}
          onClick={onDeleteButtonClick}
          variant="text"
        />
      </td>
    </>
  );
}
/* */
