import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaretDown, CaretUp, NotePencil } from '@phosphor-icons/react';
import { Badge, Button, Tag } from '@la/ds-ui-components';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
// import ErrorCard from 'components/ErrorCard/ErrorCard';
import {
  CartItemOptionsSubprogramsDetail,
  PaymentPlanSummary,
} from '../Checkout.types';
import RemoveFromCartModal from '../RemoveFromCartModal/RemoveFromCartModal';
import styles from './OrderSummaryCard.module.scss';
import OrderSummaryCardDetails from './OrderSummaryCardDetails';

export type CartItemBodyProps = {
  cartItemUuid: string;
  discountCodeBadgeLabel: string | null;
  editUrl?: string;
  isShowingAdditionalButtons?: boolean;
  location?: string;
  openPaymentPlansModal: () => void;
  programDateInfo: string | null;
  selectedPaymentPlan?: PaymentPlanSummary;
  subprograms?: CartItemOptionsSubprogramsDetail[];
  subtype: string;
};

type ActionButtonsProps = {
  editUrl?: string;
  isShowingAdditionalButtons?: boolean;
  showDetails: boolean;
  showDetailsHandler: () => void;
  openRemoveItemModal: () => void;
};

/* OrderSummaryCardBody*/
export default function OrderSummaryCardBody({
  cartItemUuid,
  discountCodeBadgeLabel,
  editUrl,
  isShowingAdditionalButtons,
  location,
  openPaymentPlansModal,
  programDateInfo,
  subprograms,
  selectedPaymentPlan,
  subtype,
}: Readonly<CartItemBodyProps>) {
  const [showDetails, setShowDetails] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const { handleCartItemDeletion } = useConsolidatedCheckout();

  function showDetailsHandler() {
    setShowDetails(!showDetails);
  }

  function openRemoveItemModal() {
    setShowRemoveItemModal(true);
  }

  function closeRemoveItemModal() {
    setShowRemoveItemModal(false);
  }

  function removeCartItem() {
    handleCartItemDeletion(cartItemUuid);
  }

  return (
    <div
      className={styles.orderSummaryCardBody}
      data-testid="order-summary-card-body"
    >
      {/*{hasDeleteCartItemError && (*/}
      {/*  <ErrorCard message="There was an error removing this item. Please try removing again in a few seconds." />*/}
      {/*)}*/}
      <DivisionList subprograms={subprograms} />
      {isShowingAdditionalButtons ? (
        <PaymentOptions
          discountCodeBadgeLabel={discountCodeBadgeLabel}
          openPaymentPlansModal={openPaymentPlansModal}
          selectedPaymentPlanName={selectedPaymentPlan?.name}
        />
      ) : null}
      <ActionButtons
        editUrl={editUrl}
        isShowingAdditionalButtons={isShowingAdditionalButtons}
        showDetails={showDetails}
        showDetailsHandler={showDetailsHandler}
        openRemoveItemModal={openRemoveItemModal}
      />
      {showDetails ? (
        <OrderSummaryCardDetails
          location={location}
          programDateInfo={programDateInfo}
          subprograms={subprograms}
          subtype={subtype}
        />
      ) : null}
      {showRemoveItemModal ? (
        <RemoveFromCartModal
          open={showRemoveItemModal}
          closeModal={closeRemoveItemModal}
          removeCartItem={removeCartItem}
          // loading={isDeletingCartItem}
        />
      ) : null}
    </div>
  );
}
/* */

/* DivisionList*/
function DivisionList({
  subprograms = [],
}: Readonly<{
  subprograms?: CartItemOptionsSubprogramsDetail[];
}>) {
  return (
    <div className={styles.divisionList}>
      {subprograms.map(({ subprogramName, registrations, subprogramId }) => (
        <Tag
          ariaLabel={subprogramName}
          contentText={subprogramName}
          numberBadgeAmount={registrations.length}
          key={subprogramId}
        />
      ))}
    </div>
  );
}
/* */

/* ActionButtons */
function ActionButtons({
  editUrl,
  isShowingAdditionalButtons,
  showDetails,
  showDetailsHandler,
  openRemoveItemModal,
}: Readonly<ActionButtonsProps>) {
  const navigate = useNavigate();

  function editButtonClickHandler() {
    if (editUrl) {
      navigate(editUrl);
    }
  }

  return (
    <div className={styles.actionButtons}>
      <Button
        rightIcon={
          showDetails ? (
            <CaretUp size={20} weight="bold" />
          ) : (
            <CaretDown size={20} weight="bold" />
          )
        }
        size="medium"
        variant="outline"
        onClick={showDetailsHandler}
      >
        {showDetails ? 'Hide details' : 'Show details'}
      </Button>
      {isShowingAdditionalButtons ? (
        <>
          <Button
            disabled={!editUrl}
            hasPadding={false}
            onClick={editButtonClickHandler}
            size="small"
            variant="text"
          >
            Edit
          </Button>
          <Button
            hasPadding={false}
            onClick={openRemoveItemModal}
            size="small"
            variant="text"
          >
            Remove
          </Button>
        </>
      ) : null}
    </div>
  );
}
/* */

/* Payment Options */
function PaymentOptions({
  discountCodeBadgeLabel,
  openPaymentPlansModal,
  selectedPaymentPlanName,
}: Readonly<{
  discountCodeBadgeLabel: string | null;
  openPaymentPlansModal: () => void;
  selectedPaymentPlanName?: string;
}>) {
  return (
    <div className={styles.paymentOptions}>
      {selectedPaymentPlanName ? (
        <PaymentPlanName
          openPaymentPlansModal={openPaymentPlansModal}
          selectedPaymentPlanName={selectedPaymentPlanName}
        />
      ) : null}
      {discountCodeBadgeLabel ? (
        <Badge size="small" text={discountCodeBadgeLabel} />
      ) : null}
    </div>
  );
}

function PaymentPlanName({
  openPaymentPlansModal,
  selectedPaymentPlanName,
}: Readonly<{
  openPaymentPlansModal: () => void;
  selectedPaymentPlanName: string;
}>) {
  return (
    <button onClick={openPaymentPlansModal} className={styles.paymentPlanName}>
      {selectedPaymentPlanName}
      <NotePencil weight="bold" size={20} />
    </button>
  );
}
