export var PaymentNetwork;
(function (PaymentNetwork) {
    PaymentNetwork["AmericanExpress"] = "American Express";
    PaymentNetwork["Discover"] = "Discover";
    PaymentNetwork["MasterCard"] = "MasterCard";
    PaymentNetwork["Visa"] = "Visa";
})(PaymentNetwork || (PaymentNetwork = {}));
export var CartItemType;
(function (CartItemType) {
    CartItemType["TournamentTeamRegistration"] = "TOURNAMENT_TEAM_REGISTRATION";
    CartItemType["TournamentPlayerRegistration"] = "TOURNAMENT_PLAYER_REGISTRATION";
    CartItemType["TournamentStaffRegistration"] = "TOURNAMENT_STAFF_REGISTRATION";
})(CartItemType || (CartItemType = {}));
